<template>
  <v-rating
    :value="stars"
    background-color="grey lighten-2"
    color="warning"
    length="5"
    readonly
  ></v-rating>
</template>

<script>
export default {
  props: {
    stars: {
      default: 0,
      type: Number
    }
  }
};
</script>

<style lang="scss" scope>

.v-icon {
  padding: 2px !important;
}

</style>
