<template>
  <form class="chat-box" @submit="onSubmit($event)">
    <input v-model="text" placeholder="Escribir un mensaje" type="text" />
    <button :disabled="text === ''">Enviar</button>
  </form>
</template>

<script>
export default {
  name: "ChatBox",
  data: () => ({
    text: "",
  }),
  methods: {
    onSubmit(event) {
      this.$emit("submit", event, this.text);
      this.text = "";
    },
  },
};
</script>

<style scoped>
.chat-box {
  width: 100%;
  display: flex;
}

input {
  width: min(100%, 20rem);
  flex-grow: 1;
  border: 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.1);
}

button {
  border: 0;
  background: #2a60ff;
  color: white;
  cursor: pointer;
  padding: 1rem;
}

button:disabled {
  opacity: 0.5;
}
</style>
