<template>
  <div class="">
    <v-chip
      class="v-chip-light-bg secondary--text mr-2"
      color="secondary"
      v-for="(attachment, index) in localAttachs"
      :key="index"
    >
      <span class="mr-2"
        ><a
          :href="`${attachment.src}`"
          target="_blank"
          rel="noopener noreferrer"
          download
          ><IconDownload /></a
      ></span>
      {{ attachment.name }}
    </v-chip>
  </div>
</template>

<script>
import { computed, ref, watch } from "@vue/composition-api";

import IconDownload from "@/components/icons/Download";

export default {
  props: {
    attachments: {
      default: () => [],
      type: Array,
    },
  },
  components: {
    IconDownload,
  },

  setup(props) {
    const localAttachs = ref(props.attachments);

    watch(
      () => props.attachments,
      (attachs) => {
        localAttachs.value = attachs;
      }
    );

    return {
      localAttachs,
    };
  },
};
</script>

<style></style>
