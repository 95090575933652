import { render, staticRenderFns } from "./ReadRating.vue?vue&type=template&id=12c1c6ac&"
import script from "./ReadRating.vue?vue&type=script&lang=js&"
export * from "./ReadRating.vue?vue&type=script&lang=js&"
import style0 from "./ReadRating.vue?vue&type=style&index=0&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VRating } from 'vuetify/lib/components/VRating';
installComponents(component, {VRating})
