<template>
  <div class="" v-if="user">
    <slot name='title' />

    <v-row>
      <v-col sm="12" xs="12" md="12" :lg="showRating ? '8' : '12'">
        <CardSection>
          <v-row>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Nombre de la empresa"
                :value="user.company"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Persona de contacto"
                :value="user.name"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Dirección"
                :value="user.address"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <SelectLocationComunes
                readonly
                :value="user.location_id"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <SelectLocationCities
                readonly
                :value="user.city_id"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Correo electrónico"
                :value="user.email"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="RUT"
                :value="user.rut"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Teléfono"
                :value="user.phone"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Años de la empresa"
                :value="user.years_bussines"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Whatsapp"
                :value="user.wattsapp"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Instagram"
                :value="user.instagram"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Facebook"
                :value="user.facebook"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Linkedin"
                :value="user.linkedin"
                class="my-2"
              />
            </v-col>
            <v-col md="6" cols="12">
              <FormTextField
                readonly
                label="Sitio web"
                :value="user.web"
                class="my-2"
              />
            </v-col>
          </v-row>
        </CardSection>
      </v-col>
      <v-col sm="12" xs="12" md="12" lg="4" v-if="showRating">
        <CardSection title="Valoraciones" v-if="showRating">
          <BadgeStarts :stars="user.total_rating" class="badge-starts" />
          <div
            class="mt-6"
            v-for="(rating, index) in user.ratings"
            :key="index"
          >
            <ReadRating :stars="rating.stars" />
            <p>{{ rating.comment }}</p>
          </div>
        </CardSection>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectLocationCities from "@/components/general/SelectLocationCities";
import SelectLocationComunes from "@/components/general/SelectLocationComunes";
import FormTextField from "@/components/forms/TextField";
import CardSection from "@/components/general/CardSection";
import ReadRating from "@/components/general/ReadRating";
import BadgeStarts from '@/components/general/BadgeStarts';

export default {
  props: {
    user: {
      default: null,
    },
    showRating: {
      default: false,
      type: Boolean
    },
  },
  components: {
    SelectLocationCities,
    SelectLocationComunes,
    FormTextField,
    CardSection,
    ReadRating,
    BadgeStarts,
  },
  setup(props) {

    return {};
  },
};
</script>

<style lang="scss" scoped>
.badge-starts {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 30px;
}
</style>
