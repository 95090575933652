<template>
  <v-chip :color="readStatus.color" outlined>{{ readStatus.label }}</v-chip>
</template>

<script>
import { computed, watch, ref } from "@vue/composition-api";
export default {
  props: {
    status: {
      default: null,
      type: String,
    },
  },

  setup(props) {
    const { status } = props;
    const localStatus = ref(status);

    const readStatus = computed(() => {
      switch (localStatus.value) {
        case "open":
          return {
            label: "Abierto",
            color: "success",
          };
        case "closed":
          return {
            label: "Cerrado",
            color: "warning",
          };
        default:
          return {
            label: status,
            color: "default",
          };
      }
    });

    watch(
      () => props.status,
      (status) => {
        localStatus.value = status;
      }
    );

    return {
      readStatus,
    };
  },
};
</script>

<style></style>
