<template>
  <v-container v-if="quote" class="mt-16">
    <v-tabs
      class="my-4 wrapper-tabs"
      hide-slider
      style="box-shadow: none !important"
    >
      <v-tab :href="`#quote`">Información Cotización</v-tab>
      <v-tab :href="`#profile`" v-if="company">Datos Condominio</v-tab>

      <v-tab-item value="quote">
        <v-row>
          <v-col sm="12" md="8" lg="8">
            <CardSection title="Información Cotización" tile>
              <div class="d-flex ga-3 flex-column gap-16">
                <div class="item">
                  <h4 class="h-4">Descripción</h4>
                  <p class="my-0">{{ quote.description }}</p>
                </div>
                <div class="item">
                  <h4 class="h-4">Garantia del servicio o producto</h4>
                  <p class="my-0">{{ quote.warranty }}</p>
                </div>
                <div class="item">
                  <h4 class="h-4">Formas de pago</h4>
                  <p class="my-0">{{ quote.payment_method }}</p>
                </div>
                <div class="item">
                  <h4 class="h-4">Adjuntos</h4>
                  <TagFiles :attachments="quote.attachments" class="mt-4" />
                </div>
              </div>
            </CardSection>

            <CardSection title="Información del proyecto">
              <div class="d-flex ga-3 flex-column gap-16">
                <div class="item">
                  <h4 class="h-4">Titulo</h4>
                  <p class="my-0">{{ project.name }}</p>
                </div>
                <div class="item d-flex align-center gap-10">
                  <h4 class="h-4">Fecha:</h4>
                  <p class="my-0">{{ project.created_at }}</p>
                </div>
                <div class="item d-flex align-center gap-10">
                  <h4 class="h-4">Estado:</h4>
                  <ProjectStatus :status="project.status" />
                </div>
                <div class="item">
                  <h4 class="h-4">Vigencia de cotización</h4>
                  <p class="my-0">{{ project.date_dmy }}</p>
                </div>
              </div>
            </CardSection>
          </v-col>
          <v-col sm="12" md="4" lg="4">
            <CardSection title="Historial mensajes">
              <ChatQuotation :quotation="quote" />
            </CardSection>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item value="profile">
        <InfoProfile :user="company" />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import {
  ref,
  onMounted,
  computed,
  getCurrentInstance,
} from "@vue/composition-api";
import CardSection from "@/components/general/CardSection";
import ProjectStatus from "@/components/general/projects/Status";
import TagFiles from "@/components/general/TagFiles";
import InfoProfile from "@/components/worker/InfoProfile.vue";
import ChatQuotation from "@/components/general/ChatQuotation.vue";
import api from "@/services";
import router from "@/router";

export default {
  name: "WorkerQuoteDetail",
  components: {
    CardSection,
    ProjectStatus,
    TagFiles,
    InfoProfile,
    ChatQuotation,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const quote = ref(null);

    const project = computed(() => {
      return quote.value?.project;
    });

    const company = computed(() => quote.value?.company);

    const getQuoteById = async ({ projectId }) => {
      try {
        const { data: response } = await api.getQuoteById(projectId);
        const { data } = response;
        quote.value = data;
      } catch (error) {}
    };

    onMounted(() => {
      const { id } = router.currentRoute.params;

      if (id) getQuoteById({ projectId: id });
    });

    return {
      quote,
      project,
      company,
    };
  },
};
</script>

<style lang="scss" scope></style>
