<template>
  <v-text-field
    hide-details="auto"
    dense
    v-bind="$attrs"
    v-on="$listeners"
    @input="onChangeInput"
  ></v-text-field>
</template>

<script>
import { ref, watch } from "@vue/composition-api";

export default {
  props: {},
  setup(props, { emit }) {

    const onChangeInput = (value) => emit("onChangeInput", value);
    return {
      onChangeInput,
    };
  },
};
</script>

<style></style>
