<template>
  <div class="d-flex flex-column">
    <Scroll-Div width="100%" height="500px" view-class="px-2" v-chat-scroll>
      <div class="messages">
        <Message
          v-for="message in messages"
          :key="message.id"
          :class="['message', { right: message.is_mine }]"
          :dark="message.is_mine"
          :text="message.text"
          :author="message.author"
        />
      </div>
    </Scroll-Div>
    <ChatBox class="chat-box" @submit="onSubmit" />
  </div>
</template>

<script>
import Message from "@/components/general/chat/Message.vue";
import ChatBox from "@/components/general/chat/ChatBox.vue";
import api from "@/services";

export default {
  name: "ChatQuotation",
  props: {
    quotation: {
      default: () => null,
      type: Object,
    },
  },
  components: {
    Message,
    ChatBox,
  },
  created() {
    this.getChat();
  },
  methods: {
    async getChat() {
      try {
        if (!this.quotationId) return;
        const { data: response } = await api.getQuoteMessages(this.quotationId);
        const { data } = response;
        this.messages = data;
      } catch (error) {}
    },
    async onSubmit(event, text) {
      event.preventDefault();

      try {
        if (!this.quotationId) return;
        const { data: response } = await api.addQuoteMessages(
          this.quotationId,
          {
            text,
          }
        );
        const { data } = response;
        this.messages = [...this.messages, data];
      } catch (error) {}
    },
  },
  computed: {
    quotationId() {
      return this.quotation?.id;
    },
  },
  data: () => ({
    messages: [],
  }),
};
</script>

<style scoped>
.messages {
  flex-grow: 1;
  overflow: auto;
  padding: 1rem;
}

.message + .message {
  margin-top: 1rem;
}

.message.right {
  margin-left: auto;
}
</style>
