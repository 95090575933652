<template>
  <v-select
    :value="value"
    dense
    hide-details="auto"
    v-bind="$attrs"
    v-on="$listeners"
    @input="onInputSelect"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: {
      default: null
    },
  },
  setup(props, { emit }) {

    const onInputSelect = (value) => {
      emit('input', value)
    };

    return {
      onInputSelect
    }
  }  
}
</script>

<style>

</style>