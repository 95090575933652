<template>
    <img v-if="badge" :src="require(`@/assets/images/app/${badge}.png`)" v-bind="$attrs" />
</template>

<script>
import { computed } from "@vue/composition-api";
export default {
    props: {
        stars: {
            default: 0
        }
    },
    setup(props) {
        const {stars} = props;

        const badge = computed(() => {
            if (stars >= 5) return 'Oro';
            if (stars >= 4) return 'Plata';
            if (stars > 0 && stars < 4) return 'Bronze';

            return null;
        })

        return {
            badge
        }
    }
}
</script>

<style lang="scss" scoped></style>