<template>
  <FormSelectField
    :value="value"
    placeholder="Ciudad"
    :label="label"
    :items="items"
    itemText="name"
    itemValue="id"
    v-bind="$attrs"
    v-on="$listeners"
    @input="onInputSelect"
  />
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";

import FormSelectField from "@/components/forms/Select";
import api from "@/services";

export default {
  props: {
    value: {
      default: null
    },
    label: {
      default: 'Ciudad'
    },
  },
  components: {
    FormSelectField,
  },

  setup(props, { emit }) {
    const items = ref([]);

    const onInputSelect = (data) => {
      emit('input', data)
    }

    const getLocationCities = async () => {
      try {
        const { data } = await api.getLocationCities();
        items.value = data;
      } catch (error) {}
    };

    onMounted(() => {
      getLocationCities();
    });
    return {
      items,

      onInputSelect
    };
  },
};
</script>

<style></style>
